import React from "react";
import Header from "./Header";
import MobileNavMenu from "./MobileNavMenu";
import HeadMarquee from "./HeadMarquee";
import Maintenance from "../Maintenance";
import { loginMaintenance } from "../../_actions";
import { connect } from "react-redux";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiData: {},
    }
  }

  componentDidMount() {
    this.verifyMaintenance()
  }

  verifyMaintenance() {
    const payload = {}
    this.props.dispatch(
      loginMaintenance(payload, (result) => {
        if (result) {
          this.setState({ apiData: result.data });
        } else {
          this.setState({ apiData: result });

        }
      })
    );
    // sideMaintenance(payload).then((data) => this.setState({ apiData: data }))
  }

  render() {
    return (
      <>
        {this.state.apiData.maintenance_mode != 1 ?
          <div className="main-root-contian">
            <MobileNavMenu {...this.props} />
            <Header {...this.props} />
            <HeadMarquee />
            {this.props.children}
          </div>
          : <Maintenance />}
      </>
    );
  }
}

const maptostateprops = (state) => ({
  ...state,
});
export default connect(maptostateprops)(Layout);