import React from "react";
import { getclientAnnouncementRequest } from "../../_actions";
import { connect } from "react-redux";
import { getStorage } from "../../utils";

class HeadMarquee extends React.Component {
  componentDidMount() {
    const userDetail = getStorage("clientLogin", "object", {});
    // if (userDetail && userDetail.token) {
    this.props.dispatch(getclientAnnouncementRequest());
    // }
  }

  clientAnnouncementView() {
    const { clientAnnouncement } = this.props;
    const list = [];
    for (const key in clientAnnouncement) {
      // console.log("ASdsad", clientAnnouncement, key)
      if (Object.hasOwnProperty.call(clientAnnouncement, key)) {
        list.push("");
        list.push(clientAnnouncement[key]);
        list.push("");
        list.push("");
      }
    }
    return list;
  }

  render() {
    const { announcement, clientAnnouncement } = this.props;
    const announce = announcement ? announcement : clientAnnouncement
    return (
      <ul className={" marquee"} id="dfdf">
        {announce && (
          <marquee>
            {announcement ? (
              <li>{announcement}</li>
            ) : (
              this.clientAnnouncementView().map((item) => <li>{item}</li>)
            )}
          </marquee>
        )}
      </ul>
    );
  }
}
const mapPropsToState = (state) => ({
  clientAnnouncement: state.match.clientAnnouncement,
});
export default connect(mapPropsToState)(HeadMarquee);
