import React from "react";
import { connect } from "react-redux";

class RulesComponent extends React.Component {
  state = {
    language: "hindi",
  };
  render() {
    return (
      <>
        <div className="lang-switch-btn">
          <div className="lang-btn-container">
            <div
              id="hindi"
              className={this.state.language === "hindi" ? "active" : ""}
            >
              <font
                style={{ verticalAlign: "inherit" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ language: "hindi" });
                }}
              >
                <font style={{ verticalAlign: "inherit" }}>हिंदी</font>
              </font>
            </div>
            <div
              id="english"
              className={this.state.language === "english" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                this.setState({ language: "english" });
              }}
            >
              <font style={{ verticalAlign: "inherit" }}>
                <font style={{ verticalAlign: "inherit" }}>english</font>
              </font>
            </div>
          </div>
        </div>
        {this.state.language === "hindi" ? (
          <HindiRuleComponent />
        ) : (
          <EnglishRuleComponent />
        )}
      </>
    );
  }
}

const HindiRuleComponent = () => {
  return (
    <>
      <div className="rules-main-container">
        <div className="row"></div>
        <ul className="hindi-note-text">
          <li>
            <b></b>
            <p style={{ textAlign: "center" }}>
              <span>
                {/* &#127951; */}
                <span role="img" aria-label="emoji name">
                  {" "}
                  🏏{" "}
                </span>{" "}
                !!  royalsuper Matka में आपका स्वागत है !!{" "}
                <span role="img" aria-label="emoji name">
                  {" "}
                  🏏{" "}
                </span>
                <br />
                <br />
                नोट :- royalsuper के सभी ID मैं रूले, इनसाइड आउटसाइड(अंदर-बाहर), और तीन
                पत्ती ये सभी लाइव गेम इन-प्ले हो गए हे| आपको गेम के आवश्यकता हो
                तो आप अपने एजेंट से संपर्क करे| royalsuper GROUP
              </span>
            </p>
          </li>
        </ul>
        <br />
        <ol className="hindi-rules-list">
          <li>
            कृपया royalsuper Matka के नियमों को समझने के लिए यहां कुछ मिनट दें, और अपने
            अनुसार समझ लें ।
          </li>
          <li>
            1 से 2 मिनट और 2 से 4 पैसे के अंतर में खाई/लगाई पर आपके प्रॉफिट के
            सौदे काट दिए जाएगे| इसके लिए क्लाइंट का कोई भी वाद विवाद मान्य नहीं
            होगा और पैसा आपको देना होगा|{" "}
          </li>
          <li>
            सभी क्लाइंट को सुचित किया जाता है की कृपया सामान्य पासवर्ड ना रखें
            उदा : 1234,12345,123456..etc
          </li>
          <li> लॉग इन करने के बाद अपना पासवर्ड बदल लें |</li>
          <li>प्रत्येक गेम के लिए 10.0/- कॉइन्स चार्ज रहेगा|</li>
          <li>
            यदि आप मैच या सेशन का एक भी सौदा नहीं करते हो, ऐसे में आपसे 10.0/-
            कॉइन्स का चार्ज नहीं लिया जायेगा |
          </li>
          <li>
            खेल रद्द या टाई होने पर सभी सौदे रद्द कर दिए जाएंगे और लेनदेन सेशन
            जो पूरा हो गया है उस पर किया जाएगा।
          </li>
          <li>
            मैच का सौदा कम से कम 100.0 और अधिकतम 1500000.0 है और सेशन का सौदा कम
            से कम 100.0 और अधिकतम 500000.0 है।
          </li>
          <li>
            मैच के दौरान भाव को देख और समझ कर ही सौदा करें | किये गए किसी भी
            सौदे को हटाया या बदला नहीं जायेगा | सभी सौदे के लिए आप स्वयं
            जिम्मेवार हैं |
          </li>
          <li>यहाँ सभी सौदे लेजर से मान्य किये जायेंगे |</li>
          <li>इंटरनेट कनेक्शन प्रॉब्लम की जिम्मेवारी आपकी रहेगी |</li>
          <li>
            मैच शुरू होने के पहले अगर कम ओवर का मैच हो जाता है तो एडवांस फैंसी,
            सेशन के सौदे हटा दिए जाएंगे |
          </li>
        </ol>

        <ul className="hindi-note-text">
          <li>
            नोट : सर्वर या वेबसाइट में किसी तरह की खराबी आने या बंद हो जाने पर
            केवल किए गए सौदे ही मान्य होंगे | ऐसी स्तिथि में किसी तरह का
            वाद-विवाद मान्य नहीं होगा |
          </li>
        </ul>
        <div className="row">
          <div className="main_menu_btn">
            <a
              href="/dashboard"
              className="btn btn-danger purp_btn change_password_btn"
              id="create_bets"
            >
              MAIN MENU
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const EnglishRuleComponent = () => {
  return (
    <>
      <div className="rules-main-container">
        <div className="row"></div>
        <ul className="hindi-note-text">
          <li>
            <b></b>
            <p style={{ textAlign: "center" }}>
              🏏 !! Welcome To  royalsuper Matka !! 🏏<br></br>
              Note :- royalsuper Ke Sabhi ID Me Roulette, Inside Outside(Andar Bahar),
              &amp; Teen Patti Yee sabhi Live Game inplay Ho gay hai aapko Game
              ke Requirement ho tho aap apne Agent se Contact kare. royalsuper GROUP
            </p>
          </li>
        </ul>
        <br />
        <ol className="english-rules-list" style={{ display: "block" }}>
          <li>
            Please give few minutes to understand rules of  royalsuper Matka here, as best
            as you can.
          </li>
          <li>
            Your profit trades will be deducted on the gap between 1 to 2
            minutes and 2 to 4 paise. For this, any dispute of the client will
            not be valid and you will have to pay the money.
          </li>
          <li>
            All clients are advised that please do not keep common password eg
            :1234,12345,123456..etc
          </li>
          <li>Change your password after you log in.</li>
          <li>For every match 10.0/- coins will be charged.</li>
          <li>
            10.0/- coins will be charged if user will play any Market bet or
            Session bet in a match.
          </li>
          <li>
            If game cancelled or tie then all the deals will be cancelled and
            the transactions will be done on session which is completed.
          </li>
          <li>
            The deal of the match is at least 100.0 and maximum 1500000.0 and
            the deal of session is at least 100.0 and maximun 500000.0.
          </li>
          <li>
            During the match, please bet only after confirming the deal. Once
            the deal is confirmed, it cannot be changed or removed.
            Responsibility of every deal is yours.
          </li>
          <li>All transactions will be validated from ledger only.</li>
          <li>
            It'll be user's responsibility for internet connection problem.
          </li>
          <li>
            Match start hone ke phele agar kam over ka match ho jata hai toi
            advance fancy, session ke saude hata diye jaenge.
          </li>
        </ol>
        <ul className="hindi-note-text">
          <li>
            Note: If some kind of breakdown occurs in server or website, only
            successful bets will be accepted. In such situation, any kind of
            debate will be invalid.
          </li>
        </ul>
        <div className="row">
          <div className="main_menu_btn">
            <a
              href="/dashboard"
              className="btn btn-danger purp_btn change_password_btn"
              id="create_bets"
            >
              MAIN MENU
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const mapPropsToState = (state) => ({});

export default connect(mapPropsToState)(RulesComponent);
